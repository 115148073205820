<template>
<b-form-group 
    :id="'fieldset-horizontal-label-'+ parentFormID + '-' + index + '-' + dataName"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
    :label-for="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
    :invalid-feedback="invalidFeedback"
    :state="validationResult.all"
>
    <b-input-group>
        <template v-if="(prependComponent != null)" v-slot:prepend>
            <component 
                :is="prependComponent"
                :touch="prependInput"
                :size="size"
            ></component>
        </template>
        <b-form-input  
            :id="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
            v-model="inputValue"
            type="time"
            @input="touch($event)"
            :required="required"
            :state="validationResult.all"
            :readonly="readOnly"
            :no-wheel="true"
            :size="size"
        ></b-form-input>
        <template v-if="(appendComponent != null || showNowButton)" v-slot:append>
            <b-button 
                v-if="showNowButton"
                variant="info" 
                @click="setTimeNow"
            >
                Now
            </b-button>
            <component 
                v-if="appendComponent != null"
                :is="appendComponent"
                :touch="appendInput"
                :size="size"
            ></component>
        </template>
    </b-input-group>
</b-form-group > 
    
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'

export default {
    name: 'formtimeinput',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton
    },
    props:{
        //bus: Object,
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        value: {
            type: String,
            default: () => {return null}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
        showNowButton: {
            type: Boolean,
            default: () => {return false} 
        }
    },
    data(){
        return{
            inputValue: '',
            originalValue: '',
            isDirty: false,
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        appendInput(){
            if(this.appendComponent == 'btn-add'){
                // we are adding an item
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
                this.inputValue = "";
                this.isDirty = false;
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }else if(this.appendComponent == 'btn-remove'){
                // We are removing an item
                this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
            }else{
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }
        },
        prependInput(){
            this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
        },
        setTimeNow(){
            var d = new Date();
            var hours = d.getHours().toString()
            var mins = d.getMinutes().toString()
            this.inputValue = `${hours.padStart(2, "0")}:${mins.padStart(2, "0")}`
            this.touch();
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            var requiredRes = ( ((this.required && this.inputValue == null)) ? false : true) ;
            return {requirementFilled: requiredRes, all: requiredRes};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return '';
            else if(!res.requirementFilled)
                return 'Required';
            else
                return 'OK';
        }
    },
    mounted(){
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all} );
    },
    created(){
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
    },
    beforeMount (){
        this.originalValue = cloneDeep(this.value);
        this.inputValue = this.value;
    },
}
</script>

<style>

</style>


