<template>
    <div :key="formKey">
        <b-row>
            <b-col cols="12">
                <h2>Add BFPA Test</h2>
            </b-col>
        </b-row>
        <b-form @submit="validateForm" v-if="!submittedSuccesfully">
            
            <!-- Device Info -->
            <b-card v-if="device.fetchedModel" header="Device Info" header-class="larger-header" >
                <b-card-body>
                    <!-- Device Info Block -->
                    <b-row no-gutters>
                        <b-col sm="3" md="3" lg="2" xl="2">
                            <!-- Empty Spacer -->
                        </b-col>
                        <b-col sm="8" md="8" lg="9" xl="9">
                            <b-row no-gutters>
                                <b-col :class="(device.modelFlagged) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Model:</b> {{device.manufacturer + ' ' + device.type + ' ' + device.model}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Serial:</b> {{device.serial}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Size:</b> {{device.size}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Location:</b> {{device.location}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Hazard Level:</b> {{device.hazardLevel}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Protection Type:</b> {{device.protectionType}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Assembly ID:</b> {{device.assemblyID}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Special Flag:</b> {{device.specialFlag}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="6" xl="6" class="text-left">
                                    <b>Hazard Type:</b> {{device.hazardType}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="12" xl="12" class="text-left">
                                    <b>Notes:</b> {{device.notes}} 
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Technician Selection -->
            <b-card v-if="!formHasError" header="Select Technician" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <!-- Technician Selection -->
                            <api-select
                                :dataName="'technician_id'"
                                :inputLabel="'Technician'"
                                :apiEndpoint="'/technicians/choices.json?order=test_count'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="techChanged" 
                            ></api-select>
                        </b-col>
                    </b-row>
                    <!-- Technician Info Block -->
                    <b-row no-gutters v-if="technician.selected">
                        <b-col sm="4" md="4" lg="3" xl="3">
                            <!-- Empty Spacer -->
                        </b-col>
                        <b-col sm="8" md="8" lg="9" xl="9">
                            <b-row no-gutters>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Technician:</b> {{technician.first + ' ' + technician.last}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Company:</b> {{technician.company}} 
                                </b-col>
                                <b-col v-if="technician.email[0] != null" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>e-mail:</b> {{technician.email[0]}} 
                                </b-col>
                                <b-col v-if="technician.phone[0] != null" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Phone:</b> {{technician.phone[0]}} 
                                </b-col>
                                <b-col :class="(technician.certs.backflow_tester.num == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Cert #:</b> {{technician.certs.backflow_tester.num}} 
                                </b-col>
                                <b-col :class="(technician.certs.backflow_testero.date == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Cert Date:</b> {{technician.certs.backflow_tester.date}} 
                                </b-col>
                                <b-col :class="(technician.testKit.model == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Test Kit:</b> {{testKitModel}} 
                                </b-col>
                                <b-col :class="(technician.testKit.serial == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Test Kit Serial:</b> {{technician.testKit.serial}} 
                                </b-col>
                                <b-col :class="(testKitCalDate == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Test Kit Calibrated:</b> {{testKitCalDate}} 
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Event Date and Time Selection -->
            <b-card v-if="!formHasError" header="Event Date & Time" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col sm="12" md="6">
                            <!-- Date Selection -->
                            <date-input
                                :dataName="'event_date'"
                                :inputLabel="'Event Date'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></date-input>
                        </b-col>
                        <b-col sm="12" md="6">
                            <!-- Time Selection -->
                            <time-input
                                :dataName="'event_time'"
                                :inputLabel="'Event Time'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                                :value="'00:01'"
                            ></time-input>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Initial Test -->
            <b-card v-if="device.fetchedModel && !formHasError && technician.selected && eventTStampCompleted" header="Initial Test" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <passed-select 
                                v-if="formVisible['so_tc_issues']"
                                :dataName="'so_tc_issues'"
                                :inputLabel="'SO & TC Issues'"
                                :options="so_tc_issues_options"
                                :multiSelect="true"
                                :required="formRequired['so_tc_issues']"
                                :value="[null]"
                                :parentFormID="localFormID"
                                :displayCount="10"
                                @onTouch="fieldTouched"
                            ></passed-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isDCRPClass(device) && testValues.preliminary.so_tc_issues == null"
                                :dataName="'ck1_psid'"
                                :inputLabel="'Check 1 PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.initial.ck1_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="testValues.initial.ck1_psid != null  && testValues.initial.ck1_psid != '' && testValues.initial.ck1_psid == 0 && testValues.preliminary.so_tc_issues == null"
                                :dataName="'ck1_leak'"
                                :inputLabel="'Check 1 Leak'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.ck1_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <bool-select
                                v-if="isRP(device) && testValues.preliminary.so_tc_issues == null"
                                :dataName="'rv_leak'"
                                :inputLabel="'RV Leaking'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leak', value:true},{text: 'No Leak', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.rv_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <decimal-2x2-input
                                v-if="isRP(device) && testValues.initial.rv_leak == false && testValues.preliminary.so_tc_issues == null"
                                :dataName="'rv_open_at'"
                                :inputLabel="'RV Opened At'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.initial.rv_open_at"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <bool-select
                                v-if="isRP(device) && testValues.initial.rv_open_at == 0 && testValues.preliminary.so_tc_issues == null"
                                :dataName="'rv_did_not_open'"
                                :inputLabel="'RV Did Not Open?'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'RV Did Not Open', value:true},{text: 'RV Opened', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.rv_did_not_open"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isDCRPClass(device) && testValues.preliminary.so_tc_issues == null"
                                :dataName="'ck2_psid'"
                                :inputLabel="'Check 2 PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.initial.ck2_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="testValues.initial.ck2_psid != null && testValues.initial.ck2_psid != '' && testValues.initial.ck2_psid == 0 && testValues.preliminary.so_tc_issues == null"
                                :dataName="'ck2_leak'"
                                :inputLabel="'Check 2 Leak'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.ck2_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="isPVB(device) && testValues.preliminary.so_tc_issues == null"
                                :dataName="'pvb_ag_open'"
                                :inputLabel="'PVB AG Open'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'AG Opened', value:true},{text: 'AG Did Not Open', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.pvb_ag_open"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isPVB(device) && testValues.initial.pvb_ag_open == true && testValues.preliminary.so_tc_issues == null"
                                :dataName="'pvb_ag_psid'"
                                :inputLabel="'PVB AG PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.initial.pvb_ag_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isPVB(device) && testValues.preliminary.so_tc_issues == null"
                                :dataName="'pvb_cv_psid'"
                                :inputLabel="'PVB CV PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.initial.pvb_cv_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="isPVB(device) && testValues.initial.pvb_cv_psid == 0 && testValues.preliminary.so_tc_issues == null"
                                :dataName="'pvb_cv_leak'"
                                :inputLabel="'PVB CV Leak'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leak', value:true},{text: 'CV Closes Tight', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.pvb_cv_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="testValues.preliminary.so_tc_issues == null"
                                :dataName="'so1_close'"
                                :inputLabel="'SO1 Close'"
                                :required="true"
                                :parentFormID="localFormID"
                                :threeState="true"
                                :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false},{text: 'N/A', value:null}]"
                                :size="'lg'"
                                :value="testValues.initial.so1_close"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6" >
                            <bool-select
                                v-if="testValues.preliminary.so_tc_issues == null"
                                :dataName="'so2_close'"
                                :inputLabel="'SO2 Close'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false}]"
                                :size="'lg'"
                                :value="testValues.initial.so2_close"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col cols="12" class="mt-4">

                        </b-col>
                        <!-- Initial Test Result Block -->
                        <b-col cols="12" v-if="testValues.initValid">
                            <b-card
                                header="Initial Test Result" 
                                body-text-variant="white"
                                :body-bg-variant="(initialTestResult.value == 'PASS') ? 'success' : (initialTestResult.value == 'FAIL') ? 'danger': 'warning'"
                            >
                                <b-card-text>
                                    <em>{{initialTestResult.value}}</em>
                                </b-card-text>
                                <template v-slot:footer v-if="initialTestResult.failureMode != null">
                                    <div class="explain-fail">{{initialTestResult.failureMode}}</div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <b-card v-if="device.fetchedModel && !formHasError && initialTestResult.value == 'FAIL'" header="Repair" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <passed-select 
                                :dataName="'quick_repair_notes'"
                                :inputLabel="'Repairs Performed'"
                                :options="quick_repair_notes_options"
                                :multiSelect="true"
                                :required="true"
                                :value="[null]"
                                :parentFormID="localFormID"
                                :displayCount="14"
                                @onTouch="fieldTouched"
                            ></passed-select>
                        </b-col>
                    </b-row>
                    <b-row v-if="enableQRNOther">
                        <b-col cols="12">
                            <text-area
                                :dataName="'quick_repair_notes_other'"
                                :inputLabel="'Custom Repair Notes'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></text-area>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Final Test -->
            <b-card v-if="device.fetchedModel && !formHasError && testValues.repair.quick_repair_notes != null && !testValues.repair.no_final" header="Final Test" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isDCRPClass(device)"
                                :dataName="'fin_ck1_psid'"
                                :inputLabel="'Check 1 PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.final.ck1_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="testValues.final.ck1_psid != null  && testValues.final.ck1_psid != '' && testValues.final.ck1_psid == 0"
                                :dataName="'fin_ck1_leak'"
                                :inputLabel="'Check 1 Leak'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.ck1_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <bool-select
                                v-if="isRP(device)"
                                :dataName="'fin_rv_leak'"
                                :inputLabel="'RV Leaking'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leak', value:true},{text: 'No Leak', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.rv_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <decimal-2x2-input
                                v-if="isRP(device) && testValues.final.rv_leak == false"
                                :dataName="'fin_rv_open_at'"
                                :inputLabel="'RV Opened At'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.final.rv_open_at"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <bool-select
                                v-if="isRP(device) && testValues.final.rv_open_at == 0"
                                :dataName="'fin_rv_did_not_open'"
                                :inputLabel="'RV Did Not Open?'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'RV Did Not Open', value:true},{text: 'RV Opened', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.rv_did_not_open"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isDCRPClass(device)"
                                :dataName="'fin_ck2_psid'"
                                :inputLabel="'Check 2 PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.final.ck2_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="testValues.final.ck2_psid != null && testValues.final.ck2_psid != '' && testValues.final.ck2_psid == 0"
                                :dataName="'fin_ck2_leak'"
                                :inputLabel="'Check 2 Leak'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.ck2_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="isPVB(device)"
                                :dataName="'fin_pvb_ag_open'"
                                :inputLabel="'PVB AG Open'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'AG Opened', value:true},{text: 'AG Did Not Open', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.pvb_ag_open"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isPVB(device) && testValues.final.pvb_ag_open == true"
                                :dataName="'fin_pvb_ag_psid'"
                                :inputLabel="'PVB AG PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.final.pvb_ag_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <decimal-2x2-input
                                v-if="isPVB(device)"
                                :dataName="'fin_pvb_cv_psid'"
                                :inputLabel="'PVB CV PSID'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="testValues.final.pvb_cv_psid"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                v-if="isPVB(device) && testValues.final.pvb_cv_psid == 0"
                                :dataName="'fin_pvb_cv_leak'"
                                :inputLabel="'PVB CV Leak'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Leak', value:true},{text: 'CV Closes Tight', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.pvb_cv_leak"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <!-- TODO MAKE CALCULATED -->
                            <bool-select
                                :dataName="'fin_so1_close'"
                                :inputLabel="'SO1 Close'"
                                :required="true"
                                :parentFormID="localFormID"
                                :threeState="true"
                                :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false},{text: 'N/A', value:null}]"
                                :size="'lg'"
                                :value="testValues.final.so1_close"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <bool-select
                                :dataName="'fin_so2_close'"
                                :inputLabel="'SO2 Close'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false}]"
                                :size="'lg'"
                                :value="testValues.final.so2_close"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <!-- Final Test Result Block -->
                        <b-col cols="12" v-if="testValues.finValid">
                            <b-card
                                header="Final Test Result" 
                                body-text-variant="white"
                                :body-bg-variant="(finalTestResult.value == 'PASS' || finalTestResult.value == 'REPAIRED & PASSED') ? 'success' : (finalTestResult.value == 'FAIL') ? 'danger': 'warning'"
                            >
                                <b-card-text>
                                    <em>{{finalTestResult.value}}</em>
                                </b-card-text>
                                <template v-slot:footer v-if="finalTestResult.failureMode != null">
                                    <div class="explain-fail">{{finalTestResult.failureMode}}</div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <b-card v-if="device.fetchedModel && !formHasError && technician.selected && eventTStampCompleted" header="Notes" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <passed-select 
                                :dataName="'test_quick_notes'"
                                :inputLabel="'Test Quick Notes'"
                                :options="equipment_test_notes_options"
                                :multiSelect="true"
                                :required="false"
                                :parentFormID="localFormID"
                                :displayCount="18"
                                :value="testValues.addtl.test_quick_notes"
                                @onTouch="fieldTouched"
                            ></passed-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <text-area
                                :dataName="'test_notes_other'"
                                :inputLabel="'Custom Test Notes'"
                                :required="false"
                                :parentFormID="localFormID"
                                :value="testValues.addtl.test_notes_other"
                                @onTouch="fieldTouched"
                            ></text-area>
                        </b-col>
                    </b-row>
                    <b-col md="12" lg="6">
                        <bool-select
                            :dataName="'verified_downstream_pressure'"
                            :inputLabel="'Verified Downstream Pressure'"
                            :required="true"
                            :parentFormID="localFormID"
                            :threeState="true"
                            :options="[{text: 'Yes', value:true},{text: 'No', value:false},{text: 'N/A', value:null}]"
                            :size="'lg'"
                            :value="testValues.addtl.verified_downstream_pressure"
                            @onTouch="fieldTouched"
                        ></bool-select>
                    </b-col>
                </b-card-body>
            </b-card>
            <b-row class="mt-3">
                <!-- Spacer -->
            </b-row>
            
            
            <b-button-toolbar justify v-if="!formHasError">
                <b-button v-if="!submittedSuccesfully" variant="warning" @click="$router.go(-1)">Discard Test and Exit</b-button>
                <b-button v-else variant="info"  @click="$router.go(-1)">Exit</b-button>
                <b-overlay :show="isSubmitting" rounded="sm">
                    <b-button v-if="!submittedSuccesfully" variant="success" @click="formValidation()">Validate & Submit</b-button>
                    <b-button v-else variant="success" disabled>Submitted</b-button>
                </b-overlay>
            </b-button-toolbar>
        </b-form>
        <b-modal 
            id="validation-failure-modal"
            size="xl"
            centered
            title="Unable To Submit Test"
            header-bg-variant="danger"
            header-text-variant="light"
            body-bg-variant="light"
            body-text-variant="dark"
        >
            <b-container fluid>
                <b-row>
                    <b-col cols="12">
                        <b>This test cannot be submitted in it's current state, please fix the following problems before submitting:</b>
                    </b-col>
                </b-row>
                <hr>
                <b-row v-for="(vf, index) in validationFailures" :key="index">
                    <b-col cols="12">
                        {{vf}}
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <hr>
        <b-modal
            id="submission-completed"
            centered
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hide-header-close
            header-text-variant="success"
            title="Test Submitted Successfully"
        >
            <b-button-toolbar justify v-if="!formHasError">
                <b-button variant="info" @click="$router.go(-1)">Exit</b-button>
                <b-button variant="info" @click="viewThisTest()">View This Test</b-button>
                <b-button variant="info" @click="goToDevice()">Go To Device</b-button>
            </b-button-toolbar>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

// Form Components
import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import DateInput from '@/components/FormDateInput.vue'
import TimeInput from '@/components/FormTimeInput.vue'

export default {
    name: 'testentry',
    components: {
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'date-input': DateInput,
        'time-input': TimeInput
    },
    props:{
        deviceID:{
            type: String,
            default: () => { return null; }
        },
        localFormID: {
            type: String,
            default: () =>{ return uuidv4(); }
        },
        formID: {
            type: String,
            default: () =>{ return null; }
        },
        inspectionTarget: {
            type: String,
            default: () =>{ return null; }
        },
        titleElements: {
            type: Array,
            default: () => { return []; }
        },
        showTitle: {
            type: Boolean,
            default: () => { return true; }
        },
        showBGColor: {
            type: Boolean,
            default: () => { return false; }
        },
        deviceInfo: {
            type: Object,
            default: () => { return null; }
        }
    },
    data(){
        return{
            name_pluaral: 'Test Bed',
            formKey: 1,
            id: null,
            formHasError: false,
            inspect: null,
            anyDirty: false,
            enableQRNOther: false,
            validationFailures: [],
            eventDate: null,
            eventTime: null,
            eventTStampCompleted: false,
            isSubmitting: false,
            submittedSuccesfully: false,
            ownEventID: null,
            testValues: {
                initValid: false,
                finValid: false,
                preliminary:{
                    so_tc_issues: null
                },
                initial: {
                    ck1_psid: null,
                    ck1_leak: null,
                    ck2_psid: null,
                    ck2_leak: null,
                    rv_leak: null,
                    rv_open_at: null,
                    rv_did_not_open: null,
                    pvb_ag_open: null,
                    pvb_ag_psid: null,
                    pvb_cv_psid: null,
                    pvb_cv_leak: null,
                    so1_close: null,
                    so2_close: null
                },
                repair: {
                    quick_repair_notes: null,
                    quick_repair_notes_other: null,
                    no_final: false
                },
                final:{
                    ck1_psid: null,
                    ck1_leak: null,
                    ck2_psid: null,
                    ck2_leak: null,
                    rv_leak: null,
                    rv_open_at: null,
                    rv_did_not_open: null,
                    pvb_ag_open: null,
                    pvb_ag_psid: null,
                    pvb_cv_psid: null,
                    pvb_cv_leak: null,
                    so1_close: null,
                    so2_close: null
                },
                addtl:{
                    test_quick_notes: null,
                    test_notes_other: null,
                    verified_downstream_pressure: null
                }
            },
            // Specific To The Test Entry
            formRequired: {
                so_tc_issues: false,
            },
            formVisible: {
                so_tc_issues: true,
            },
            so_tc_issues_options: [
                {value: 'Bad SO1 Shaft', text: 'Bad SO1 Shaft'},
                {value: 'Bad SO2 Shaft', text: 'Bad SO2 Shaft'},
                {value: 'Bad TC 1', text: 'Bad TC 1'},
                {value: 'Bad TC 2', text: 'Bad TC 2'},
                {value: 'Bad TC 3', text: 'Bad TC 3'},
                {value: 'Bad TC 4', text: 'Bad TC 4'},
                {value: 'PVB Air Gap leaking Upon Arrival', text: 'Air Gap leaking Upon Arrival.'},
                {value: 'RV Leaking', text: 'RV Leaking'},
                {value: 'Previously Failed', text: 'Previously Failed'}
            ],
            quick_repair_notes_options: [
                {value: 'Cleaned', text: 'Cleaned'},
                {value: 'Replaced Disk & O-ring', text: 'Replaced Disk & O-ring'},
                {value: 'Replaced AG Disc Assy', text: 'Replaced AG Disc Assy'},
                {value: 'Replaced TC1', text: 'Replaced TC1'},
                {value: 'Replaced TC2', text: 'Replaced TC2'},
                {value: 'Replaced TC3', text: 'Replaced TC3'},
                {value: 'Replaced TC4', text: 'Replaced TC4'},
                {value: 'Replaced SO Handle(s)', text: 'Replaced SO Handle(s)'},
                {value: 'Replaced RV Rubber Parts', text: 'Replaced RV Rubber Parts'},
                {value: 'Replaced Ck1 Ck2 SO1 and SO2 Packing and Bolts', text: 'Replaced Ck1 Ck2 SO1 and SO2 Packing and Bolts'},
                {value: 'Large BFPA - Repairs done as separate Job', text: 'Large BFPA - Repairs done as separate Job'},
                {value: 'BFPA needs to be Replaced', text: 'BFPA needs to be Replaced'},
                {value: 'Other', text: 'Other (Custom Note)'},
            ],
            equipment_test_notes_options: [
                {value: 'FBY', text: 'FBY'},
                {value: 'NOH', text: 'NOH'},
                {value: 'TTS', text: 'TTS'},
                {value: 'IINS', text: 'Imp Inst Not Serviceable'},
                {value: 'IIOMB', text: 'Imp Inst Outside Meter Box'},
                {value: 'IISP', text: 'Imp Inst Sweated Pipe'},
                {value: 'Storm Debris', text: 'Storm Debris'},
                {value: 'No Union(s)', text: 'No Union(s)'},
                {value: 'IIPVB', text: 'Imp Inst PVC'},
                {value: 'Damaged MB', text: 'Damaged MB'},
                {value: 'No SO1 handle', text: 'No SO1 handle'},
                {value: 'No SO2 handle', text: 'No SO2 handle'},
                {value: 'SO1 CUA', text: 'SO1 Closed On Arrival'},
                {value: 'SO2 CUA', text: 'SO2 Closed On Arrival'},
                {value: 'SO1 Open', text: 'SO1 Open'},
                {value: 'SO2 Open', text: 'SO2 Open'},
                {value: 'Tagged', text: 'Tagged'},
            ],
            nonTestableDeviceTypes: ['Dual Check', 'NONE', 'UNKNOWN', 'Single Check', 'SDC'],
            technician: {
                selected: false,
                id: null,
                first: null,
                last: null,
                company: null,
                email: [null],
                phone: [null],
                certifications: {
                    treeo: {
                        num: null,
                        date: null
                    }
                },
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
                testKit: {
                    manufacturer: null,
                    model: null,
                    serial: null,
                    calDate: null
                }
            },
            device: {
                id: null,
                fetchedDevice: false,
                fetchedModel: false,
                modelFlagged: false,
                disabled: false,
                inactive: null,
                modelID: null,
                manufacturer: null,
                type: null,
                model: null,
                serial: null,
                size: null,
                gps:{
                    lat: null,
                    lng: null,
                    acc: null
                },
                location: null,
                notes: null,
                hazardType: null,
                hazardTypeID: null,
                hazardLevel: null,
                hazardLevelID: null,
                protectionType: null,
                protectionTypeID: null,
                gradeType: null,
                gradeTypeID: null,
                specialFlag: null,
                scheduleCode: null,
                connectionID: null,
                condition: [null],
                conditionNotes: null,
                assemblyID: null
            },
            formDef:{
                meta: {
                    name: 'Add BFPA Test',
                    title: 'Add BFPA Test',
                    formParentKey: null
                },
                fields: {}
            },
            formTitle: "Loading...",
            initialTestFields: ['ck1_psid', 'ck1_leak', 'ck2_psid', 'ck2_leak', 'rv_open_at', 'rv_did_not_open', 'rv_leak', 'pvb_ag_open', 'pvb_ag_psid', 'pvb_cv_psid', 'pvb_cv_leak', 'so1_close', 'so2_close'],
            finalTestFields: ['quick_repair_notes', 'quick_repair_notes_other', 'fin_ck1_psid', 'fin_ck1_leak', 'fin_ck2_psid', 'fin_ck2_leak', 'fin_rv_open_at', 'fin_rv_did_not_open', 'fin_rv_leak', 'fin_pvb_ag_open', 'fin_pvb_ag_psid', 'fin_pvb_cv_psid', 'fin_pvb_cv_leak', 'fin_so1_close', 'fin_so2_close'],
            classDCRPClassTypes: ['DC','DCDA','DDC','RP','RPDA'],
            classDCTypes: ['DC','DCDA','DDC'],
            classRPTypes: ['RP','RPDA'],
            classPVBTypes: ['AVB','PVB'],
            classSingleTypes: ['SDC', 'Single Check'],
            visibleForDCRPClass: ['ck1_psid', 'ck1_leak', 'ck2_psid', 'ck2_leak'],
            visibleForRP: ['ck1_psid', 'ck1_leak', 'ck2_psid', 'ck2_leak', 'rv_open_at', 'rv_did_not_open', 'rv_leak'],
            visibleForPVB: ['pvb_ag_open', 'pvb_ag_psid', 'pvb_cv_psid', 'pvb_cv_leak'],
            visibleForAll: ['so1_close', 'so2_close'],
            preliminaryTestFields: ['so_tc_issues']
        }
    },
    methods: {
        viewThisTest(){
            this.$router.push({ path: `/home/test/${this.ownEventID}`});
        },
        goToDevice(){
            var thisFormType = 'devices';
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + this.deviceID })
            }
        },
        isDCRPClass(device = this.device){
            if(this.classDCRPClassTypes.includes(device.type)){
                return true;
            }else{
                return false;
            }
        },
        isRP(device = this.device){
            if(this.classRPTypes.includes(device.type)){
                return true;
            }else{
                return false;
            }
        },
        isPVB(device = this.device){
            if(this.classPVBTypes.includes(device.type)){
                return true;
            }else{
                return false;
            }
        },
        isDC(device = this.device){
            if(this.classDCTypes.includes(device.type)){
                return true;
            }else{
                return false;
            }
        },
        isSDC(device = this.device){
            if(this.classSingleTypes.includes(device.type)){
                return true;
            }else{
                return false;
            }
        },
        isInitialField(fieldDataName){
            if(this.initialTestFields.includes(fieldDataName)){
                return true;
            }else{
                return false;
            }
        },
        isPreliminaryField(fieldDataName){
            if(this.preliminaryTestFields.includes(fieldDataName)){
                return true;
            }else{
                return false;
            }
        },
        fieldTouched(dataName){
            var fieldValue = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName});
            // console.log(dataName)
            // console.log(fieldValue);
            // Update Validity
            if(this.isInitialField(dataName)){
                this.testValues.initial[dataName] = fieldValue;
                this.testValues.initValid = this.initialTestValid();
            }else if(this.isPreliminaryField(dataName)){
                // Treat SO TC Issues Special
                if(dataName == 'so_tc_issues'){
                    if(fieldValue.length == 1 && fieldValue[0] == null){
                        this.testValues.preliminary[dataName] = null;
                        this.testValues.initValid = false;
                        this.SOTCChangesMade(fieldValue);
                    }else{
                        this.testValues.preliminary[dataName] = fieldValue;
                        this.testValues.initValid = true;
                        // Set The Form Values With SOTC In Mind
                        this.SOTCChangesMade(fieldValue)
                    }
                }else{
                    // Everybody Else Is Just Boring
                    this.testValues.preliminary[dataName] = fieldValue;
                }
            }else if(dataName == 'quick_repair_notes'){
                this.testValues.repair.quick_repair_notes = fieldValue;
                if(fieldValue.includes('Large BFPA - Repairs done as separate Job') || fieldValue.includes('BFPA needs to be Replaced')){
                    this.testValues.repair.no_final = true;
                }else{
                    this.testValues.repair.no_final = false;
                }
                if(fieldValue.includes('Other')){
                    this.enableQRNOther = true;
                }else{
                    this.enableQRNOther = false;
                }
            }else if(dataName == 'quick_repair_notes_other'){
                this.testValues.repair.quick_repair_notes_other = fieldValue;
            }else if(dataName == 'event_date'){
                this.eventDate = fieldValue;
                if(this.eventDate != null && this.eventTime != null){
                    this.eventTStampCompleted = true;
                    console.log("Event TimeStamp Filled")
                }
            }else if(dataName == 'event_time'){
                this.eventTime = fieldValue;
                if(this.eventDate != null && this.eventTime != null){
                    this.eventTStampCompleted = true;
                    console.log("Event TimeStamp Filled")
                }
            }else if(dataName == 'test_quick_notes' || dataName == 'test_notes_other' || dataName == 'verified_downstream_pressure'){
                if(dataName == 'test_quick_notes'){
                    if(fieldValue.length == 1 && fieldValue[0] == null){
                        this.testValues.addtl[dataName] = null;
                    }else{
                        this.testValues.addtl[dataName] = fieldValue;
                    }
                }else{
                    this.testValues.addtl[dataName] = fieldValue;
                }
                
            }else if(dataName.substr(0,3) == 'fin'){
                // Part of Final Test
                this.testValues.final[dataName.split('fin_')[1]] = fieldValue;
                this.testValues.finValid = this.finalTestValid();
            }
            // Use fieldValue to determine the next stage of behavior

        },
        techChanged(dataName){
            // Called Exclusively From The Technician Field
            var fieldValue = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName});
            // Clear the technician value
            this.technician = {
                selected: false,
                id: null,
                first: null,
                last: null,
                company: null,
                email: [null],
                phone: [null],
                certifications: {
                    treeo: {
                        num: null,
                        date: null
                    }
                },
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
                testKit: {
                    manufacturer: null,
                    model: null,
                    serial: null,
                    calDate: null
                }
            };
            this.technician.id = fieldValue;
            instance.get(process.env.VUE_APP_API_BASE_URL + '/technicians/' + fieldValue + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var tech = response.data.result.records[0];
                        // Update Techncician Information In Model
                        this.technician.selected = true;
                        this.technician.first = tech.tech_first_name;
                        this.technician.last = tech.tech_last_name;
                        this.technician.company = tech.tech_company;
                        if(tech.tech_email != null){
                            this.technician.email = tech.tech_email;
                        }else{
                            this.technician.email = [null]
                        }
                        if(tech.tech_phone != null){
                            this.technician.phone = tech.tech_phone;
                        }else{
                            this.technician.phone = [null]
                        }
                        if(_.has(tech.certifications, 'treeo')){
                            this.technician.certifications.treeo.num = tech.certifications.treeo.num;
                            this.technician.certifications.treeo.date = tech.certifications.treeo.date;
                        }else{
                            this.technician.certifications = {treeo: {num: null,date: null}};
                        }

                        if(_.has(tech.certs, 'backflow_tester')) {
                            this.technician.certs.backflow_tester.num = tech.certs.backflow_tester.num;
                            this.technician.certs.backflow_tester.date = tech.certs.backflow_tester.date;
                        } else {
                            this.technician.certs.backflow_tester = {num: null, date: null};
                        }

                        this.technician.testKit.manufacturer = tech.test_kit_manufacturer;
                        this.technician.testKit.model = tech.test_kit_model;
                        this.technician.testKit.serial = tech.test_kit_serial;
                        this.technician.testKit.calDate = tech.test_kit_cal_date;
                        // Generate Tech Full Name
                        var techName = this.technician.first + ' ' + this.technician.last;
     
                        // Update Technician Fields
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'tech_name', value: techName, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_number', value: this.technician.certs.backflow_tester.num, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_date', value: this.technician.certs.backflow_tester.date, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_kit_sn', value: this.technician.testKit.serial, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_klt_cal_date', value: this.technician.testKit.calDate, valid: true, isDirty: true} );
                    }else{
                        this.makeToast("Failed To Fetch Technician Info", "Technician Information Could Not Be Retrieved Or Was Not Retrieved", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Technician Info", "Technician Information Could Not Be Retrieved Or Was Not Retrieved", 'danger');
                    console.log(error);
                });
        },
        SOTCChangesMade(value){
            var sotcFailObj = {
                tcCausesCk1Fail: false,
                tcCausesCk2Fail: false,
                tcCausesCvFail: false,
                tcCausesAgFail:false,
                tcCausesRVLeak: false,
                tcPreviouslyFailed: false,
                soShaftCausesSO1Leak: false,
                soShaftCausesSO2Leak: false,
            };
            if(value.includes('Bad TC 1')){
                if(!this.isPVB()){
                    sotcFailObj.tcCausesCk1Fail = true;
                }else{
                    sotcFailObj.tcCausesCvFail = true;
                }
            }
            if(value.includes('Bad TC 2')){
                if(!this.isPVB()){ // Is Not PVB
                    sotcFailObj.tcCausesCk1Fail = true;
                }else{ // Is PVB
                    sotcFailObj.tcCausesAgFail = true;
                }
            }
            if(value.includes('Bad TC 3') || value.includes('Bad TC 4')){
                if(!this.isPVB()){ // Is Not PVB
                    sotcFailObj.tcCausesCk2Fail = true;
                }
            }
            if(value.includes('Bad SO1 Shaft')){
                sotcFailObj.soShaftCausesSO1Leak = true;
            }
            if(value.includes('Bad SO2 Shaft')){
                sotcFailObj.soShaftCausesSO2Leak = true;
            }
            if(value.includes('RV Leaking')){
                sotcFailObj.tcCausesRVLeak = true;
            }
            if(value.includes('PVB Air Gap leaking Upon Arrival')){
                sotcFailObj.tcCausesAgFail = true;
            }
            //Check for Previously Failed
            if(value.includes('Previously Failed')){
                sotcFailObj.tcPreviouslyFailed = true;
            }

            if(sotcFailObj.tcCausesCk1Fail){
                this.testValues.initial.ck1_leak = true;
            }else{
                this.testValues.initial.ck1_leak = null;
            }
            //Check 2
            if(sotcFailObj.tcCausesCk2Fail){
                this.testValues.initial.ck2_leak = true;
            }else{
                this.testValues.initial.ck2_leak = null;
            }
            //PVB CV
            if(sotcFailObj.tcCausesCvFail){
                this.testValues.initial.pvb_cv_leak = true;
            }else{
                this.testValues.initial.pvb_cv_leak = null;
            }
            //PVB AG
            if(sotcFailObj.tcCausesAgFail){
                this.testValues.initial.pvb_ag_open = false;
            }else{
                this.testValues.initial.pvb_ag_open = null;
            }
            //RV
            if(sotcFailObj.tcCausesRVLeak){
                this.testValues.initial.rv_leak = false;
            }else{
                this.testValues.initial.rv_leak = null;
            }
            //Previously Failed
            if(sotcFailObj.tcPreviouslyFailed){
                //Set CK1, CK2, and PVB CV leak.
                this.testValues.initial.ck1_leak = true;
                this.testValues.initial.ck2_leak = true;
                this.testValues.initial.pvb_cv_leak = true;
            }else{
                this.testValues.initial.ck1_leak = null;
                this.testValues.initial.ck2_leak = null;
                this.testValues.initial.pvb_cv_leak = null;
            }
            if(sotcFailObj.soShaftCausesSO1Leak){
                // SO1 Did Not Close Tight
                this.testValues.initial.so1_close = false;
            }else{
                this.testValues.initial.so1_close = null;
            }
            if(sotcFailObj.soShaftCausesSO2Leak){
                // SO2 Did Not Close Tight
                this.testValues.initial.so2_close = false;
            }else{
                this.testValues.initial.so2_close = null;
            }
            
        },
        setSOTCRequired(){
            this.formRequired.so_tc_issues = !this.formRequired.so_tc_issues;
        },
        formValidation(){
            var failureMessages = [];
            if(this.formHasError){
                // Form Has Error Which Prevents Test Adding
                failureMessages.push("The Device is Non Testable, Does Not Exist, Or The Technician Selected Is Invalid. ")
                this.validationFailures = failureMessages;
                this.$bvModal.show('validation-failure-modal')
            }
            if(!this.eventTStampCompleted){
                // Form Has Error Which Prevents Test Adding
                failureMessages.push("Event Date and Time Are Required")
                this.validationFailures = failureMessages;
                this.$bvModal.show('validation-failure-modal')
            }
            if(this.technician.selected && this.device.fetchedModel){
                var initialValid = this.initialTestValid();
                var finalValid = this.finalTestValid();
                console.log(`Initial Test: ${initialValid} Final Test: ${finalValid}`)
                if(initialValid && finalValid){
                    // Is Okay, Test is Valid
                    this.packageTestAndSubmit()
                }else{
                    var form = this.$store.getters.getForm(this.localFormID);
                    var keys = _.keys(form);
                    var invalidFieldNames = _.filter(keys, (k)=>{
                        return form[k].valid == false;
                    });
                    invalidFieldNames.forEach((fn)=>{
                        failureMessages.push(`${fn} is required`);
                    })
                    this.validationFailures = failureMessages;
                    this.$bvModal.show('validation-failure-modal')
                }
            }else{
                // Either Technician Or Device Is Invalid
                if(this.technician.id == null){
                    failureMessages.push("Technician is required");
                } 
                if(this.device.fetchedModel == null){
                    failureMessages.push("Device Specified Does Not Exist Or Is Non Testable");
                }
                this.validationFailures = failureMessages;
                this.$bvModal.show('validation-failure-modal')
            }
        },
        validateForm(evt){
            evt.preventDefault()
        },
        fetchValues(evt){
            evt.preventDefault()
        },
        fetchDeviceInformation(){
            if(this.deviceID == null){
                makeToast("Unable To Enter BFPA Test, Missing or Malformed Device ID", "Device ID is Null, Check The URL or Link and Attempt Again", 'danger');
                return;
            }
            instance.get(process.env.VUE_APP_API_BASE_URL + '/devices/' + this.deviceID + '.json?dref=true')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        this.device.fetchedDevice = true;
                        var dev = response.data.result.records[0];
                        this.device.id = dev.device_id;
                        this.device.disabled = dev.disabled;
                        this.device.inactive = dev.inactive;
                        this.device.modelID = dev.device_model_id;
                        this.device.serial = dev.device_serial;
                        this.device.size = dev.device_size;
                        this.device.gps = {
                            lat: dev.device_latitude,
                            lng: dev.device_longitude,
                            acc: dev.device_gps_accuracy
                        };
                        this.device.location = dev.device_location;
                        this.device.notes = dev.device_comments;
                        this.device.hazardType = dev.hazard_type;
                        this.device.hazardTypeID = dev.hazard_type_id;
                        this.device.hazardLevel = dev.hazard_level;
                        this.device.hazardLevelID = dev.hazard_level_id;
                        this.device.protectionType = dev.protection_type;
                        this.device.protectionTypeID = dev.protection_type_id;
                        this.device.gradeType = dev.grade_type;
                        this.device.gradeTypeID = dev.grade_type_id;
                        this.device.specialFlag = dev.special_flag;
                        this.device.scheduleCode = dev.schedule_code;
                        this.device.condition = (dev.condition == null) ? [null] : dev.condition;
                        this.device.conditionNotes = dev.condition_notes;
                        this.device.connectionID = dev.connection_id;
                        this.device.assemblyID = dev.assembly_id;
                        if(this.device.modelID == null){
                            this.formHasError = true;
                            this.device.modelFlagged = true;
                            this.makeToast("Cannot Perform Test On Unknown Device Model", "Device Model ID is null, Cannot Perform Test On A Device Without A Model Selected. Return To The Device And Select A Valid Model.", 'danger');
                        }else{
                            this.fetchModelInformation();
                        }
                    }else{
                        this.makeToast("Failed To Fetch Device Info", "Device Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Device Info", "Device Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    console.log(error);
                });
        },
        fetchModelInformation(){
            if(this.device.modelID == null){
                makeToast("Unable To Enter BFPA Test", "Device Does Not Have A Valid Model", 'danger');
                return;
            }
            instance.get(process.env.VUE_APP_API_BASE_URL + '/device_models/models/' + this.device.modelID + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        this.device.fetchedModel = true;
                        var mdl = response.data.result.records[0];
                        this.device.manufacturer = mdl.manufacturer;
                        this.device.type = mdl.type;
                        this.device.model = mdl.model;
                        if(this.nonTestableDeviceTypes.includes(this.device.type)){
                            this.formHasError = true;
                            this.device.modelFlagged = true;
                            this.makeToast(`Device Type: ${this.device.type} is not testable`, "Device Type For Selected Device Is Not A Testable Device Type", 'danger');
                        }
                        console.log(this.device)
                    }else{
                        this.makeToast("Failed To Fetch Device Model Information", "Device Model Info Could Not Be Retrieved Or Was Not Retrieved, Verify That This Device Has A Valid Model", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Device Model Information", "Device Model Info Could Not Be Retrieved Or Was Not Retrieved, Verify That This Device Has A Valid Model", 'danger');
                    console.log(error);
                });
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        },
        initBeforeMount(){
            // Fetch Device
            console.log(`Test Entry Opened For Device ID: ${this.deviceID}`)
            this.fetchDeviceInformation();
            this.$store.commit('initForm',{ localFormID: this.localFormID, meta: this.formDef.meta });
            
        },
        initBeforeCreate(){
            this.$store.commit('clearForm', {name: "Form Recently Cleared"});
        },
        doAllInit(){
            //this.initBeforeCreate();
            this.initBeforeMount();
        },
        initialTestValid(){
            //this.$store.getters.getEntireFormValid;
            var x = this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.initialTestFields});
            // console.log(`Initial Test Valid Returns: ${x}`);
            return x;
        },
        finalTestValid(){
            //this.$store.getters.getEntireFormValid;
            var x = this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.finalTestFields});
            // console.log(`Final Test Valid Returns: ${x}`);
            return x;
        },
        packageTestAndSubmit(){
            var repairNotes = this.testValues.repair.quick_repair_notes;
            if(repairNotes != null){
                if(repairNotes.length != 0 && repairNotes[0] != null){
                    if( this.testValues.repair.quick_repair_notes.includes('Other')){
                        repairNotes.push(this.testValues.repair.quick_repair_notes_other)
                    }
                }
                
            }
            var notes = this.testValues.addtl.test_quick_notes;
            if(notes != null){
                if(notes.length != 0 && notes[0] != null){
                    if(this.testValues.addtl.test_notes_other != null){
                        notes.push(this.testValues.addtl.test_notes_other);
                    }
                }
            }
            
            
            var test = {
                device_id: this.device.id,
                technician_id: this.technician.id,
                technician: this.technician.first + ' ' + this.technician.last,
                cert_date: this.technician.certs.backflow_tester.date,
                cert_number: this.technician.certs.backflow_tester.num,
                test_kit_sn: this.technician.testKit.serial,
                test_kit_cal_date: this.technician.testKit.calDate,
                so_tc_issues: this.testValues.preliminary.so_tc_issues,
                ck1_psi: this.testValues.initial.ck1_psid,
                ck1_leak: this.testValues.initial.ck1_leak,
                init_cv1_close: (this.testValues.initial.ck1_leak != null) ? !this.testValues.initial.ck1_leak : null,
                rv_open_at: this.testValues.initial.rv_open_at,
                rv_did_not_open: this.testValues.initial.rv_did_not_open,
                ck2_psi: this.testValues.initial.ck2_psid,
                ck2_leak: this.testValues.initial.ck2_leak,
                init_cv2_close: (this.testValues.initial.ck2_leak != null) ? !this.testValues.initial.ck2_leak : null,
                init_pvb_air_open: this.testValues.initial.pvb_ag_open,
                ini_pvb_ag: this.testValues.initial.pvb_ag_psid,
                ini_pvb_cv: this.testValues.initial.pvb_cv_psid,
                pvb_cv_leak: this.testValues.initial.pvb_cv_leak,
                init_so1_close: this.testValues.initial.so1_close,
                init_so1_leak: (this.testValues.initial.so1_close != null) ? !this.testValues.initial.so1_close : null,
                init_so2_close: this.testValues.initial.so2_close,
                init_so2_leak: (this.testValues.initial.so2_close != null) ? !this.testValues.initial.so2_close : null,
                initial_test_result: this.initialTestResult.value,
                quick_repair_notes: repairNotes,
                final_rpdc_ck1_psid: this.testValues.final.ck1_psid,
                final_rpdc_ck1_close: (this.testValues.final.ck1_leak != null) ? !this.testValues.final.ck1_leak : null,
                final_rpdc_cv2_psid: this.testValues.final.ck2_psid,
                final_rpdc_cv2_close: (this.testValues.final.ck2_leak != null) ? !this.testValues.final.ck2_leak : null,
                final_rp_rv_psid: this.testValues.final.rv_open_at,
                fin_pvb_air_open: this.testValues.final.pvb_ag_open,
                fin_pvb_ag_psid: this.testValues.final.pvb_ag_psid,
                fin_pvb_cv_psid: this.testValues.final.pvb_cv_psid,
                fin_pvb_cv_leak: this.testValues.final.pvb_cv_leak,
                fin_so1_close: this.testValues.final.so1_close,
                fin_so2_close: this.testValues.final.so2_close,
                verified_downstream_pressure: this.testValues.addtl.verified_downstream_pressure,
                event_notes: notes,
                event_tstamp: this.eventDate + ' ' + this.eventTime,
                final_test_result: (this.finalTestResult.value == "REPAIRED & PASSED") ? "REPAIRPASS" : this.finalTestResult.value,
                device_latitude: this.device.gps.lat,
                device_longitude: this.device.gps.lng,
                device_gps_accuracy: this.device.gps.acc,
                condition: (this.device.condition.length == 1 && this.device.condition[0] == null) ? null : this.device.condition
            };
            this.isSubmitting = true;
            instance.post(process.env.VUE_APP_API_BASE_URL + '/events/test.json', test)
                .then((response)=>{
                    this.isSubmitting = false; 
                    this.submittedSuccesfully = true;
                    this.$bvModal.show('submission-completed')
                    this.ownEventID = response.data.result.eventID;
                })
                .catch((error)=>{
                    console.log("Failed To Submit Test")
                    this.isSubmitting = false; 
                    this.submittedSuccesfully = false;
                })
        }
    },
    watch:{
        '$route': 'doAllInit'
    },
    computed: {
        
        childrenDataNames: function(){
            // Remove Meta From Consideration
            var valid = _.omit(this.$store.getters.getForm(this.localFormID), 'meta');
            // Get Keys
            return _.keys(valid);
        },
        testKitModel: function(){
            var mdl = '';
            if(this.technician.testKit.manufacturer != null){
                mdl += this.technician.testKit.manufacturer + ' ';
            }
            if(this.technician.testKit.model != null){
                mdl += this.technician.testKit.model;
            }
            return mdl
        },
        testKitCalDate: function(){
            var date = null;
            if(this.technician.testKit.calDate != null){
                date = new Date(this.technician.testKit.calDate).toISOString().substr(0,10);
            }
            return date;
        },
        compInitValid: function(){
            return this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.initialTestFields});
        },
        initialTestResult: function(){
            var result = {
                value: "PASS",
                failureMode: null,
                blame: {
                    module: null,
                    key: null
                }
            };
            // Check If SO TC Issues Filled
            if(this.testValues.preliminary.so_tc_issues != null){ 
                result = {
                    value: 'FAIL',
                    failureMode: `SO TC Issues: ${this.testValues.preliminary.so_tc_issues.join(', ')}`,
                    blame: {
                        module: "preliminary",
                        key:"so_tc_issues"
                    }
                };
                return result;
            }

            // fail when so1 or so2 not close
            if(this.testValues.initial.so1_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO1 Leak",
                    blame: {
                        module: "initial",
                        key:"so1_close"
                    }
                };
                return result;
            }
            if(this.testValues.initial.so2_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO2 Leak",
                    blame: {
                        module: "initial",
                        key:"so2_close"
                    }
                };
                return result;
            }
            if(this.testValues.repair.quick_repair_notes != null){ 
                result = {
                    value: 'FAIL',
                    failureMode: "Repairs Noted",
                    blame: {
                        module: "repair",
                        key:"quick_repair_notes"
                    }
                };
                return result;
            }
            // Device Type Dependent
            // All RP & DC Class Valves Are Subject To These Rules
            if(this.isDCRPClass()){
                if(this.testValues.initial.ck1_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "initial",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.ck2_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "initial",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.ck2_psid != null && this.testValues.initial.ck2_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK2 PSID < 1",
                        blame: {
                            module: "initial",
                            key:"ck2_psid"
                        }
                    };
                    return result;
                }
            }

            // More Specific Rules
            if(this.isRP(this.device)){ // RP Rules
                if(this.testValues.initial.ck1_psid != null && this.testValues.initial.ck1_psid < 5){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV CK1 PSID < 5",
                        blame: {
                            module: "initial",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.rv_open_at != null && this.testValues.initial.rv_open_at < 2){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Open At < 1",
                        blame: {
                            module: "initial",
                            key:"rv_open_at"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.rv_did_not_open == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Did Not Open",
                        blame: {
                            module: "initial",
                            key:"rv_did_not_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.rv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Leaking",
                        blame: {
                            module: "initial",
                            key:"rv_leak"
                        }
                    };
                    return result;
                }
                result = {
                    value: 'PASS',
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else if(this.isDC(this.device)){ // DC Rules
                if(this.testValues.initial.ck1_psid != null && this.testValues.initial.ck1_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "DC CK1 PSID < 1",
                        blame: {
                            module: "initial",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                result = {
                    value: 'PASS',
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else if(this.isPVB()){
                if(this.testValues.initial.pvb_ag_open == false){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG Did Not Open",
                        blame: {
                            module: "initial",
                            key:"pvb_ag_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.pvb_ag_psid != null && this.testValues.initial.pvb_ag_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG PSID < 1",
                        blame: {
                            module: "initial",
                            key:"pvb_ag_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.pvb_cv_psid != null && this.testValues.initial.pvb_cv_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV PSID < 1",
                        blame: {
                            module: "initial",
                            key:"pvb_cv_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.pvb_cv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV Leaking",
                        blame: {
                            module: "initial",
                            key:"pvb_cv_leak"
                        }
                    };
                    return result;
                }
                result = {
                    value: 'PASS',
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else{
                result = {
                    value: 'Not a Testable Device',
                    failureMode: `Device Type: ${this.device.type} is not testable`,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }
        },
        finalTestResult: function(){
           var result = {
                value: "REPAIRED & PASSED",
                failureMode: null,
                blame: {
                    module: null,
                    key: null
                }
            };
            // Pass when initial test pass
            if(this.initialTestResult.value == 'PASS'){
                result = {
                    value: "PASS",
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else if(this.testValues.repair.no_final){
                result = {
                    value: "FAIL",
                    failureMode: "Quick Repair Notes Indicate Failure Without Final Test",
                    blame: {
                        module: 'repair',
                        key: "quick_repair_notes"
                    }
                };
                return result;
            }
            // fail when so1 or so2 not close
            if(this.testValues.final.so1_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO1 Leak",
                    blame: {
                        module: "final",
                        key:"so1_close"
                    }
                };
                return result;
            }
            if(this.testValues.final.so2_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO2 Leak",
                    blame: {
                        module: "final",
                        key:"so2_close"
                    }
                };
                return result;
            }
            // Device Type Dependent
            // All RP & DC Class Valves Are Subject To These Rules
            if(this.isDCRPClass()){
                if(this.testValues.final.ck1_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "final",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.ck2_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "final",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.ck2_psid != null && this.testValues.final.ck2_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK2 PSID < 1",
                        blame: {
                            module: "final",
                            key:"ck2_psid"
                        }
                    };
                    return result;
                }
            }

            // More Specific Rules
            if(this.isRP(this.device)){ // RP Rules
                if(this.testValues.final.ck1_psid != null && this.testValues.final.ck1_psid < 5){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV CK1 PSID < 5",
                        blame: {
                            module: "final",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.rv_open_at != null && this.testValues.final.rv_open_at < 2){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Open At < 1",
                        blame: {
                            module: "final",
                            key:"rv_open_at"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.rv_did_not_open == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Did Not Open",
                        blame: {
                            module: "final",
                            key:"rv_did_not_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.rv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Leaking",
                        blame: {
                            module: "final",
                            key:"rv_leak"
                        }
                    };
                    return result;
                }
                return result;
            }else if(this.isDC(this.device)){ // DC Rules
                if(this.testValues.final.ck1_psid != null && this.testValues.final.ck1_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "DC CK1 PSID < 1",
                        blame: {
                            module: "final",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                return result;
            }else if(this.isPVB()){
                if(this.testValues.final.pvb_ag_open == false){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG Did Not Open",
                        blame: {
                            module: "final",
                            key:"pvb_ag_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.pvb_ag_psid != null && this.testValues.final.pvb_ag_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG PSID < 1",
                        blame: {
                            module: "final",
                            key:"pvb_ag_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.pvb_cv_psid != null && this.testValues.final.pvb_cv_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV PSID < 1",
                        blame: {
                            module: "final",
                            key:"pvb_cv_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.pvb_cv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV Leaking",
                        blame: {
                            module: "final",
                            key:"pvb_cv_leak"
                        }
                    };
                    return result;
                }
                return result;
            }else{
                result = {
                    value: 'Not a Testable Device',
                    failureMode: `Device Type: ${this.device.type} is not testable`,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            } 
        }
    },
    mounted(){
        // Setup Technician, Gauge, And Cert Fields
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'tech_name', value: this.technician.first + ' ' + this.technician.last, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_number', value: this.technician.certs.backflow_tester.num, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_date', value: this.technician.certs.backflow_tester.date, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_kit_sn', value: this.technician.testKit.serial, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_klt_cal_date', value: this.technician.testKit.calDate, valid: false} );
        //var x = this.$store.getters.getEntireFormValid;
    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    beforeMount (){
        this.initBeforeMount();
    },
    beforeCreate(){
        //this.initBeforeCreate();
    },
}
</script>

<style scoped>
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
.blue-bg{
    background-color: #ebf5ff;
    border-radius: 1em;
    border-width: 0.5em;
    padding: 0.5em;
}
.explain-fail{
    font-size: 0.75em;
}
.larger-header{
    font-size: 1.5em;
}
</style>